import React, { useState, useRef, useEffect } from "react";
import toggle from "assets/img/toggler-gold.svg";
import { Container } from "reactstrap";
import { Stack } from "@mui/material";
import outline from 'assets/img/outline.png'
import part1 from 'assets/img/part1.png'
import part1d from 'assets/img/part1d.png'
import part2d from 'assets/img/part2d.png'
import part3d from 'assets/img/part3d.png'
import part4d from 'assets/img/part4d.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import remarkable from "assets/img/img_remarkable.jpeg"
import { makeStyles} from '@mui/styles';
import TabContentPlan from "components/Content/TabContentPlan";
import Divider from '@mui/material/Divider';

import part2 from 'assets/img/part2.png'

import part3 from 'assets/img/part3.png'
import part3bis from 'assets/img/part3bis.png'  // Add this import

import part4 from 'assets/img/part4.png'
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import floor1 from "assets/img/floor0graph.png"
import floor2 from "assets/img/floor1graph.png"
import floor3 from "assets/img/floor2graph.png"
import floor4 from "assets/img/rooftopgraph.png"
import exterior from "assets/img/exterior.png"

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';




function SectionPlans() {
  const tabContents = [
    { label: "Windows", items: ["The large floor to ceiling windows with insulated double glazing, offer beautiful unobstructed views.", "Thermal breaking minimalist windows design."] },
    { label: "AC & Heater", items: ["The low temperature water underfloor heating provides a pleasant warmth evenly distributed during the winter months and has much cheaper consumption cost than electric underfloor heating.", "The Mitsubishi air conditioners are high-end, silent and energy efficient VRF units allowing independent control for each room.", "Water heating by electric accelerator."] },
    { label: "Electricity & Communications", items: ["Three phase, 63-80 amps electrical supply.", "USB or USBC sockets in each bedroom, two RJ45 network points, and telephone sockets.", "Electric board by Legrand.", "TV connection.", "Sockets by Bticino Light.", 'Up to 15 electrical points per room (depending on the room size).'] },
    { label: "Bathroom", items: ["Each bedroom may have its own bathroom according to the layout.", "Wall mounted toilet with concealed cistern from Geberit.", 'Tiles from floor to dropped ceiling.', 'Faucets will be manufactured in Italy (Pezzi)', 'The sanitary facilities in the bathrooms are minimalist and modern.', 'Bathrooms flooring tiles', 'Wall Hung Bathroom Cabinet includes a surface with built-in sink.'] },
    { label: "Kitchen", items: ["Each apartment has a lacquered custom-designed kitchen by NY Loft (nyloft.net), open to the living and dining areas with Caesarstone countertops with double sinks.", "Gas point for cooking"] },
    { label: "Doors", items: ["Entrance door of 2,40 meters high with passage of one meter and 8 centimeters thickness", "Soundproofing", "Custom made", "Lacquered paint will fit into the sleek design of the apartments", "Manufactured in Israel", "Unusual high of 2,40 meters", "Invisible hinges", "Video entry phone system."] },
    { label: "Parking & Storage", items: ["A large underground car garage on a single level offers large 270 cm wide spots allowing each owner to get out of his vehicle without hitting the neighboring vehicle.", 'Each spot will allowed a power supply unit for electric vehicles, to be installed.', 'Several spots will be available for visitors.', 'Direct access to the apartments from the parking level.', 'Storages on the parking level.'] },
    { label: "Information", items: ["Estimated monthly charges: around NIS 16/month/SQM for the first year adjustable the following years, including a reserve fund that will be allocated to renewing equipment and specific maintenance in order to keep the building in perfect condition.", "Artistic spirit characterizes the common areas with a landscaped garden decorated with sculptures.", 'A 24/7 concierge will be available at the lobby.', 'Daily cleaning.'] },
  ];
  

  const [selectedFloor, setSelectedFloor] = useState(floor1);




 
  const [isRotated, setIsRotated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const listRef = useRef(null);



  const useStyles = makeStyles({
    imageIcon: {
      height: '100%'
    },
    iconRoot: {
      textAlign: 'center'
    },
    tabs: {
  
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: 3,
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    }
  })

  const classes = useStyles();

  
  
  
 
  // Handle click to toggle rotation and visibility
  const handleToggle = () => {
    setIsRotated(!isRotated); // Toggle the rotation
    setIsVisible(prevState => !prevState); // Toggle the visibility
  };
  const textRef = useRef(null);


  // Update height when isVisible changes
  useEffect(() => {
    if (listRef.current) {
      listRef.current.style.height = isVisible ? `${listRef.current.scrollHeight}px` : '0px';
    }
  }, [isVisible]);


  const fullText = `Situated on a spacious 2400 square meter plot, a rare privilege in Jerusalem, the majestic entrance reveals a villa that appears to be single-story, but Villa Sherover unfolds over three unexpected levels, offering 1200 square meters of living space.
  The living and dining areas boast an impressive ceiling height of over 5 meters. With more than 130 square meters of space, they feature a central fireplace and open onto a 35-meter-long terrace, perfect for hosting. The designated kitchen area extends to a charming outdoor space of more than 100 square meters, part of which is shaded by a magnificent weeping tree, creating the ideal spot for al fresco dining. Vast gardens surround the property, adding a touch of serenity and grandeur to this exceptional setting.
  The enormous rooftop offers endless possibilities for creating a truly unique space. The historic façade lends Villa Sherover an unrivaled charm, a testament to its rich heritage. Sold as a blank canvas in concrete shell form, this architectural gem allows for the creation of the home of one's dreams. It is a rare and unique opportunity to own one of Jerusalem's most iconic villas, and to shape a space that fully reflects your vision. Located in a prestigious neighborhood, with a remarkable history and exceptional potential, this property is perfect for those seeking both luxury and cultural heritage.`;

  const bulletPoints = [
    "Situated on a spacious 2400 square meter plot, a rare privilege in Jerusalem.",
    "Majestic entrance reveals a villa that appears to be single-story, but unfolds over three unexpected levels.",
    "Offers 1200 square meters of interior space.",
    "Potential for a home cinema, wine cellar, spa, mikveh, and a private synagogue with independent access.",
    "Living and dining areas boast an impressive ceiling height of over 5 meters.",
    "More than 130 square meters of living space with a central fireplace.",
    "Opens onto a 35-meter-long terrace, perfect for hosting.",
    "Designated kitchen area extends to a charming outdoor space of more than 100 square meters.",
    "Part of the outdoor space is shaded by a magnificent weeping tree, ideal for al fresco dining.",
    "Vast gardens surround all property, adding serenity and grandeur.",
    "Enormous rooftop offers endless possibilities for creating a unique space.",
    "Historic façade lends Villa Sherover an unrivaled charm, a testament to its rich heritage.",
    "Sold 'AS IS', blank canvas in concrete shell form, allowing for creation of the home of one's dreams.",
    "Rare opportunity to own one of Jerusalem's most iconic villas.",
    "Located in a prestigious neighborhood, perfect for those seeking luxury and cultural heritage."
  ];

  const initialText = fullText.split('.').slice(0, 2).join('.') + '.';
  const hiddenText = fullText.split('.').slice(2).join('.');

  const initialBullets = bulletPoints.slice(0, 6);
const hiddenBullets = bulletPoints.slice(6);

  // Handle click to toggle rotation and visibility
  // const handleToggle = () => {
  //   setIsRotated(!isRotated);
  //   setIsVisible(prevState => !prevState);
  // };

  // Update height when isVisible changes
  useEffect(() => {
    if (textRef.current) {
      textRef.current.style.height = isVisible ? `${textRef.current.scrollHeight}px` : '0px';
    }
  }, [isVisible]);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div
      id='property_section'
        className="test"
        style={{
          // height: '300vh',
          width: '100%',
          position: 'relative',
        }}
      >

        <Stack
          style={{
            top: '5%',
            left: '0',
            textAlign: 'left',
            zIndex: 1,
            color: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
                        <Container maxWidth="md" style={{ paddingTop: '5%', paddingBottom: '5%' }}>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ color: '#956B35', fontWeight: 'bold' }}>The</h2>
            <h1 style={{ color: '#956B35', marginTop: '0' }}>PROPERTY</h1>

            <div style={{ color: "#8F734F", marginTop: '2rem', marginBottom: '0' }}>
            <ul style={{ paddingLeft: '20px' }}>
                  {initialBullets.map((point, index) => (
                    <li key={index} style={{ marginBottom: '15px', fontWeight: 400 }}>{point}</li>
                  ))}
                </ul>

             

              
                <div
                  ref={listRef}
                  className={`toggle-text ${isVisible ? 'visible' : 'hidden'}`}
                  style={{
                    overflow: 'hidden',
                    transition: 'height 0.3s ease-out',
                    height: '0px',
                    // marginTop:'-5px' 
                  }}
                >
                  <ul style={{ paddingLeft: '20px'}}>
                    {hiddenBullets.map((point, index) => (
                      <li key={index} style={{ marginBottom: '15px', fontWeight: 400 }}>{point}</li>
                    ))}
                  </ul>
                </div>

              <button
                className="astext"
                onClick={handleToggle}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  marginBottom: '10px',
                  color: '#8F734F'

                }}
              >
                <span style={{ marginRight: '10px', color: 'black' }}>
                {isVisible ? 'Read less' : 'Read more'}
              </span>
                <img
                  src={toggle}
                  alt="Toggle Icon"
                  style={{
                    marginLeft: '20px',
                    height: '20px',
                    width: '20px',
                    transition: 'transform 0.3s ease',
                    transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </button>

             
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <img src={exterior} style={{marginTop:'2rem', width:'80%'}} alt="Exterior" />
              </div>
          </div>
          </Container>

        

        

        




      <div className="test"
      style={{
        // backgroundColor: '#CFC4BA', // Replace this with your desired color
        backgroundSize: 'cover', // Ensures the image covers the whole div
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents repeating the image
        // height: '200vh', // Makes the div cover the full viewport height
        width: '100%', // Makes the div cover the full viewport width
        position: 'relative', // Allows for positioning of child elements
        alignItems:"center",
        justifyContent:"center"
      }}
    >

<Grid container spacing={2} sx={{ padding: 2 }}>
    <Grid item xs={12} md={6} sx={{ borderRight: { md: '1px solid #956B35' }, borderBottom: '1px solid #956B35' }}>
      <Box
        component="img"
        src={floor3}
        alt="Second Floor"
        onClick={() => handleImageClick(floor3)}
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            filter: 'brightness(1)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6} sx={{ borderBottom: '1px solid #956B35' }}>
      <Box
        component="img"
        src={floor2}
        alt="First Floor"
        onClick={() => handleImageClick(floor2)}
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            filter: 'brightness(1)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6} sx={{ borderRight: { md: '1px solid #956B35' } }}>
      <Box
        component="img"
        src={floor1}
        alt="Ground Floor"
        onClick={() => handleImageClick(floor1)}
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            filter: 'brightness(1)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Box
        component="img"
        src={floor4}
        alt="Rooftop"
        onClick={() => handleImageClick(floor4)}
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
            filter: 'brightness(1)',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }
        }}
      />
    </Grid>
  </Grid>
      


    
    </div>
     
      
     
     
    
     
         
        </Stack>

        <Divider style={{marginTop: '1rem'}}></Divider>



      </div>

      {/* Image Modal */}
      <Modal
        open={selectedImage !== null}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="fullsize-image"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          width: '95vw',
          height: '95vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img 
              src={selectedImage} 
              alt="Full size" 
              style={{ 
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }} 
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default SectionPlans;